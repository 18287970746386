<script setup>
import IconArrowDown from "../IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="drop-down drop-down-network"
        :class="{active : dropDown}"
    >
        <div class="drop-down-bg-click" @click="dropDown = false"></div>
        <button class="btn-drop-down" 
            @click="dropDown = !dropDown"
        >
            <i class="icon-box">
                <img src="../../assets/images/network/bnb.png" alt="" />
            </i>
            <span>{{ chosenNetwork }} </span>
            <div class="icon-arrow">
                <IconArrowDown />
            </div>
        </button>
        <div class="items-wrap">
            <div class="items-container">
                <ul>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/network/polygon.png" alt="" />
                        </i>
                        Polygon
                    </li>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/network/ethereum.png" alt="" />
                        </i>
                        Ethereum
                    </li>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/network/arbitrum.png" alt="" />
                        </i>
                        Arbitrum
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            chosenNetwork: "Bnb",
            dropDown: false,
        };
    },
};
</script>
