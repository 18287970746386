<template>
    <div class="push" @click="closeNotif()">
        <div class="push-item" :class="{ fail: notification.type === 'error', success: notification.type === 'success' }">
            <!-- <div class="push-item success " > -->
            <!-- <div class="push-item" > -->
            <div class="push-icon">
                <IconError class="icon-error" />
                <IconCheck class="icon-check" />
                <IconNotification class="icon-notif" />
            </div>
            <div class="push-msg" v-html="notification.message"></div>
            <div class="push-close">
                <button>
                    <IconClose />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import IconClose from "./IconsComponent/IconClose.vue";
    import IconError from "./IconsComponent/IconError.vue";
    import IconCheck from "./IconsComponent/IconCheck.vue";
    import IconNotification from "./IconsComponent/IconNotification.vue";
    import { mapMutations } from "vuex";

    export default {
        props: ["notification"],
        components: {
            IconCheck,
            IconClose,
            IconError,
            IconNotification,
        },
        created() {
            if (!this.notification.gtmTag) {
                const time = this.notification.message.length > 40 ? 7500 : 5000;
                this.timeout = setTimeout(() => {
                    this.remove_notification(this.notification);
                }, time);
            }
        },
        methods: {
            ...mapMutations(["remove_notification"]),
            closeNotif() {
                this.remove_notification(this.notification);
            },
        },
        beforeDestroy() {
            clearTimeout(this.timeout);
        },
    };
</script>
