import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from "axios";
import config from "../../config.json";
var conf = config[config.env];
import { mainContractAbi } from "../assets/abi/mainContractAbi";

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export default class Core {
    constructor(vueContext) {
        this.context = vueContext;
        this.baseURL = conf.BASE_URL;

        this.init();
    }
    async checkAccount(accountType) {
        if (window.ethereum && accountType === "metamask") {
            const connected = window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(handleAccountsChanged)
                .then((res) => res)
                .catch((err) => {
                    console.error(err);
                });

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            window.ethereum.on("accountsChanged", handleAccountsChanged);

            // For now, 'eth_accounts' will continue to always return an array
            function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {
                    return false;
                    // MetaMask is locked or the user has not connected any accounts
                    // alert('Please connect to MetaMask.');
                } else if (accounts.length > 0) {
                    return true;
                }
            }
            return connected;
        }
    }

    async init() {
        if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
            const rpc = {
                // 1: "https://ethnode.cloud/",
            };
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                const url = conf.NETWORK_PARAMS.find((el) => Number(el.chainId) === Number(chainId))?.params.rpcUrls[0];
                rpc[chainId] = url;
            }

            this.providerInstance = new WalletConnectProvider({
                rpc: rpc,
            });

            if (!this.providerInstance.connected) {
                try {
                    // Create a dialogue
                    await this.providerInstance.enable();
                } catch (error) {
                    if (error.message === "User closed modal") {
                        window.localStorage.removeItem("address");
                        window.localStorage.removeItem("walletconnect");
                        window.localStorage.removeItem("selectedNetwork");
                        window.localStorage.removeItem("selectedWallet");
                    }
                }
            }
            await this.subscribeToEvents();
            const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
            const blockchain = Number(WC_Obj?.chainId);
            this.primaryPovider = new ethers.providers.Web3Provider(this.providerInstance, "any");

            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);

                this[`mainContract_${chainId}`] = new ethers.Contract(conf[chainId].MAIN_CONTRACT, mainContractAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );

                if (blockchain === Number(chainId)) {
                    this.provider = this.primaryPovider;
                    this.signer = this.provider.getSigner();
                    this.context.$store.commit("setChainId", chainId);
                    this.currentBlockchain = Number(blockchain);

                    this[`mainContract_${chainId}`] = new ethers.Contract(conf[chainId].MAIN_CONTRACT, mainContractAbi, this.provider).connect(this.signer);
                } else {
                    this[`mainContract_${chainId}`].connect(this[`provider_${chainId}`]);
                }
            }

            return true;
        } else if (window.localStorage.getItem("selectedWallet") === "metamask") {
            let blockchain = Number(window.ethereum.chainId);

            this.primaryPovider = new ethers.providers.Web3Provider(window.ethereum, "any");

            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);

                this[`mainContract_${chainId}`] = new ethers.Contract(conf[chainId].MAIN_CONTRACT, mainContractAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );

                if (blockchain === Number(chainId)) {
                    this.provider = this.primaryPovider;
                    this.signer = this.provider.getSigner();
                    this.context.$store.commit("setCurrentBlockchain", Number(chainId));
                    this.currentBlockchain = Number(blockchain);

                    this[`mainContract_${chainId}`] = new ethers.Contract(conf[chainId].MAIN_CONTRACT, mainContractAbi, this.provider).connect(this.signer);
                } else {
                    this[`mainContract_${chainId}`].connect(this[`provider_${chainId}`]);
                }
            }

            return true;
        } else if (!window.localStorage.getItem("selectedWallet")) {
            this.currentBlockchain = Number(conf.PRIMARY_BLOCKCHAIN.chainId);
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);

                this[`mainContract_${chainId}`] = new ethers.Contract(conf[chainId].MAIN_CONTRACT, mainContractAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );
            }
            return true;
        }
    }
    async subscribeToEvents() {
        try {
            if (!this.providerInstance) {
                return;
            }

            this.providerInstance.on("accountsChanged", async (accounts) => {
                let currentAccount = window.localStorage.getItem("address");
                // console.log(`connector.on("accountsChanged")`);

                const address = accounts[0];

                if (!currentAccount || address.toLowerCase() !== currentAccount.toLowerCase()) {
                    currentAccount = address;
                    localStorage.setItem("address", currentAccount);

                    // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                    location.reload();
                } else if (address.toLowerCase() === currentAccount.toLowerCase()) {
                    this.context.$store.commit("setCurrentAddress", address);
                }
            });
            this.providerInstance.on("chainChanged", async (_chainId) => {
                const currentNetwork = window.localStorage.getItem("selectedNetwork");
                // console.log(`connector.on("accountsChanged")`);

                const network = Number(_chainId);
                window.localStorage.setItem("selectedNetwork", network);

                window.location.reload();
            });

            this.providerInstance.on("connect", (error, payload) => {
                // console.log(`connector.on("connect")`);

                if (error) {
                    throw error;
                }

                this.onConnect(payload);
            });

            this.providerInstance.on("disconnect", (code, reason) => {
                // console.log(`connector.on("disconnect")`, code, reason);

                this.onDisconnect();
            });

            if (this.providerInstance.connected) {
                const { chainId, accounts } = this.providerInstance;

                this.onSessionUpdate(accounts, chainId);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* --------> PROMISES  <--------  */

    async userGlobalInfosInPromise(address) {
        return this[`mainContract_${this.currentBlockchain}`].userGlobalInfos(address);
    }
    async getEvents() {
        return this[`mainContract_${this.currentBlockchain}`].queryFilter("ReferrerRewardAdded");
    }
    async userRoundsLengthPromise(boxType, address) {
        return this[`mainContract_${this.currentBlockchain}`].getUserRoundsLength(boxType, address);
    }
    async getChildrenLengthPromise(address) {
        return this[`mainContract_${this.currentBlockchain}`].getChildrenLength(address);
    }
    async getUserRoundLedgerLength(boxType, epoch, address) {
        return this[`mainContract_${this.currentBlockchain}`].getUserRoundLedgersLength(boxType, epoch, address);
    }

    async currentEpochsPromise(boxType) {
        return this[`mainContract_${this.currentBlockchain}`].currentEpochs(boxType);
    }
    async getUserDepartSalesAndLevelPromise(address) {
        return this[`mainContract_${this.currentBlockchain}`].getUserDepartSalesAndLevel(address);
    }
    async getChildrenPromise(address, cursor, size) {
        return this[`mainContract_${this.currentBlockchain}`].getChildren(address, cursor, size);
    }
    /* --------> END PROMISES  <--------  */

    /* --------> LOOPS  <--------  */

    getUserStatsLoop(userAddress, isSupportedChain, period = 10000) {
        let _this = this;

        setTimeout(async function tick() {
            try {
                if (isSupportedChain) {
                    let userCoinBalance;
                    if (window.localStorage.getItem("selectedWallet") && window.localStorage.getItem("selectedWallet") !== "email") {
                        userCoinBalance = (await _this.getUserBalance(userAddress)).userCoinBalance;
                    }

                    const { userGlobalInfo, userChildrenLength, levelToRoundsLength } = await _this.getUserRelatedData(userAddress);
                    _this.context.$store.commit("setUserCoinBalance", userCoinBalance);
                    _this.context.$store.commit("setUserStats", userGlobalInfo);
                    _this.context.$store.commit("setUserChildrenLength", userChildrenLength);
                    _this.context.$store.commit("setUserLevelToRoundsLength", levelToRoundsLength);

                    setTimeout(tick, period);
                } else {
                    userCoinBalance = 0;
                    //TODO: Set default empty object
                    _this.context.$store.commit("setUserCoinBalance", userCoinBalance);
                    _this.context.$store.commit("setUserStats", userStats);
                }
            } catch (error) {
                console.log(error);
                setTimeout(tick, 300);
            }
        }, 300);
        // setTimeout(async function tick() {
        //     try {
        //         if (isSupportedChain) {
        //             const userDeposits = await _this.getUserDeposits(userAddress, _this.currentBlockchain);
        //             let userTransactions = await _this.getUserTransactions(userAddress, _this.currentBlockchain);

        //             _this.context.$store.commit("setUserTransactions", userTransactions);
        //             _this.context.$store.commit("setUserDeposits", userDeposits);

        //             setTimeout(tick, period / 3);
        //         } else {
        //             _this.setupDefault();
        //         }
        //     } catch (error) {
        //         console.log(error);
        //         setTimeout(tick, 300);
        //     }
        // }, 300);
    }
    getSiteData(isSupportedChain = true, period = 30000) {
        let _this = this;
        setTimeout(async function tick() {
            try {
                if (isSupportedChain) {
                    const { boxToEpochs, boxLevelToData, allInvestments } = await _this.getSiteRelatedData();
                    // const allInvestments = await _this.getSiteDataFromDb();

                    _this.context.$store.commit("setBoxToEpochs", boxToEpochs);
                    _this.context.$store.commit("setBoxLevelToData", boxLevelToData);
                    _this.context.$store.commit("setAllInvestments", allInvestments);
                    // _this.context.$store.commit("setSiteData", siteStats);
                    setTimeout(tick, period);
                } else {
                    //TODO add empty data object and set it to store
                    // _this.context.$store.commit("setSiteData", siteStats);
                }
            } catch (error) {
                console.log(error);
                if (!_this[`mainContract_${_this.currentBlockchain}`]) {
                    console.log("core is not set up");
                    return;
                }
                setTimeout(tick, 300);
            }
        }, 300);
    }
    /* --------> END LOOPS  <--------  */

    /* --------> READ METHODS  <--------  */

    async getUserRoundLedger(boxType, epoch, address) {
        //25 is a size of deposits per epoch an box, we do not think each user will have more than 25 deposits per each epoch and box. increase if this cause any issues/
        //0 is offset for case when large amount of deposits created
        /** @return
         uint256 amount;
        uint256 openTime;
        uint256 expiryTime;
        uint256 investReturnRate;
        uint256 withdrawnAmount;
        uint256 incentiveAmount;
        uint256 investReturnAmount;
        uint256 index;
        bool incentiveClaimable;
         * 
         * 
         */
        return this[`mainContract_${this.currentBlockchain}`].getUserRoundLedgers(boxType, epoch, address, 0, 25);
    }
    async roundInfoPromise(boxType, epoch) {
        return this[`mainContract_${this.currentBlockchain}`].roundInfos(boxType, epoch);
    }
    async getAllInvestmentsPromiseFromDb() {
        try {
            return axios.get(`${this.baseURL}/getAllInvestments`, {
                params: {
                    chainId: this.currentBlockchain,
                    contractAddress: this[`mainContract_${this.currentBlockchain}`].address,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    async getAllInvestmentsPromise(boxType, epoch) {
        return this[`mainContract_${this.currentBlockchain}`].getAllInvestmentsData(boxType, epoch, 0, 100);
    }
    async getAllInvestmentsPromiseFromDb() {
        try {
            return axios.get(`${this.baseURL}/getAllInvestments`, {
                params: {
                    chainId: this.currentBlockchain,
                    contractAddress: this[`mainContract_${this.currentBlockchain}`].address,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    async getUserRelatedData(address) {
        let [
            userGlobalInfo /*userRoundsLength,*/,
            userChildrenLength,
            round1Length,
            round2Length,
            round3Length,
            round4Length,
            round5Length,
            round6Length,
            userDepartSaleasAndLevel,
            userChildrensAndAddresses,
        ] = await Promise.all([
            this.userGlobalInfosInPromise(address),
            this.getChildrenLengthPromise(address),
            this.userRoundsLengthPromise(0, address),
            this.userRoundsLengthPromise(1, address),
            this.userRoundsLengthPromise(2, address),
            this.userRoundsLengthPromise(3, address),
            this.userRoundsLengthPromise(4, address),
            this.userRoundsLengthPromise(5, address),
            this.getUserDepartSalesAndLevelPromise(address),
            this.getChildrenPromise(address, 0, 200),
        ]);

        return this.parseUserData(
            userGlobalInfo,
            userChildrenLength,
            userDepartSaleasAndLevel,
            userChildrensAndAddresses[0],
            round1Length,
            round2Length,
            round3Length,
            round4Length,
            round5Length,
            round6Length
        );
    }
    async getUserRoundLedgers(boxToEpochs, address) {
        let arrayOfPromises = [];

        for (let [boxType, epoch] of Object.entries(boxToEpochs)) {
            arrayOfPromises.push(this.getUserRoundLedger(boxType, epoch, address));
        }
        const rounds = await Promise.all(arrayOfPromises);
        return this.parseUserRounds(rounds, boxToEpochs);
    }
    async getUserRoundForSpecific(box, epoch, address) {
        const res = await this.getUserRoundLedger(box, epoch, address);
        return this.parseUserRound(res, box, epoch);
    }
    async getInvestmentsForSpecific(box, epoch) {
        const res = await this.getAllInvestmentsPromise(box, epoch);

        const result = this.parseInvestmentsPerBoxOrEpoch(box, epoch, res);

        return result;
    }
    async getSiteRelatedData() {
        let arrayOfPromises = [];
        let arrayOfInvestmetsPromises = [];

        for (let i = 0; i < conf[this.currentBlockchain].PLANS.length; i++) {
            arrayOfPromises.push(this.currentEpochsPromise(i));
        }
        let allEpochs = await Promise.all(arrayOfPromises);

        arrayOfPromises = [];
        for (let i = 0; i < conf[this.currentBlockchain].PLANS.length; i++) {
            arrayOfPromises.push(this.roundInfoPromise(i, allEpochs[i].toString()));
            arrayOfInvestmetsPromises.push(this.getAllInvestmentsPromise(i, allEpochs[i].toString()));
        }

        let allRoundInfo = await Promise.all(arrayOfPromises);
        let allInvestments = await Promise.all(arrayOfInvestmetsPromises);

        return this.parseSiteData(allEpochs, allRoundInfo, allInvestments);
    }
    async getSiteDataFromDb() {
        const [allInvestments] = await Promise.all([this.getAllInvestmentsPromise()]);
        return allInvestments.data;
    }
    async getUserBalance(address) {
        try {
            const coinBalance = await this.provider.getBalance(address);

            return {
                userCoinBalance: ethers.utils.formatEther(coinBalance),
            };
        } catch (error) {
            console.log("USER BALANCE ERROR", error);
        }
    }
    async getTribeDetails(addresses) {
        let promiseArrays = addresses.map((address) => {
            return this.userGlobalInfosInPromise(address);
        });
        let res = await Promise.all(promiseArrays);
        return res.map((el, id) => {
            return { ...this.parseGlobalInfoOnly(el), elveAddress: addresses[id] };
        });
    }
    /* --------> END READ METHODS  <--------  */

    /* --------> WRITE METHODS  <--------  */

    async batchClaimPositionIncentiveReward(box, epoch, indexes) {
        debugger;
        const res = await this[`mainContract_${this.currentBlockchain}`].batchClaimPositionIncentiveReward(box, epoch, indexes);
        return res;
    }
    async batchClosePositions(box, epoch, indexes) {
        debugger;
        const res = await this[`mainContract_${this.currentBlockchain}`].batchClosePositions(box, epoch, indexes);
        return res;
    }
    async batchReportSales(addresses) {
        const res = await this[`mainContract_${this.currentBlockchain}`].batchReportSales(addresses);
        return res;
    }
    async openPosition(lendgerType, targetEpoch, targetRate, referrer, useBoost, amount) {
        if (!referrer) {
            referrer = conf[this.currentBlockchain].DEFAULT_REFERRER;
        }
        targetRate = Math.floor(targetRate * conf[this.currentBlockchain].CONSTANTS.INVEST_RATE_PERC_ROUNDING);
        let gasLimit = await this.estimateGas(
            "openPosition",
            [lendgerType, targetEpoch, targetRate, referrer, useBoost],
            ethers.utils.parseEther(amount.toString())
        );
        gasLimit = Number(gasLimit) + 10000;

        const res = await this[`mainContract_${this.currentBlockchain}`].openPosition(lendgerType, targetEpoch, targetRate, referrer, useBoost, {
            value: ethers.utils.parseEther(amount.toString()),
            gasLimit,
        });
        return res;
    }
    async claimReferrerReward(address) {
        const res = await this[`mainContract_${this.currentBlockchain}`].claimReferrerReward(address);
        return res;
    }
    async closePosition(box, epoch, index) {
        const res = await this[`mainContract_${this.currentBlockchain}`].closePosition(box, epoch, index);
        return res;
    }
    /* --------> END WRITE METHODS  <--------  */

    /* --------> PARSERS <-------- */

    parseUserData(globalInfo, childrenLength, userDepartSalesAndLevel, userChildrensAddresses, ...levels) {
        let levelToRoundsObj = levels.reduce((obj, item, index) => {
            return {
                ...obj,
                [index]: Number(item.toString()),
            };
        }, {});

        const result = {};
        const parsedGlobalInfo = {};
        parsedGlobalInfo.boostCredit = ethers.utils.formatEther(globalInfo.boostCredit["_hex"]);
        parsedGlobalInfo.maxChildrenSales = ethers.utils.formatEther(globalInfo.maxChildrenSales);
        parsedGlobalInfo.referrer = globalInfo.referrer;
        parsedGlobalInfo.referrerRewardClaimed = ethers.utils.formatEther(globalInfo.referrerRewardClaimed["_hex"]);
        parsedGlobalInfo.reportedSales = ethers.utils.formatEther(globalInfo.reportedSales);
        parsedGlobalInfo.sales = ethers.utils.formatEther(globalInfo.sales);
        parsedGlobalInfo.salesLevel = Number(globalInfo.salesLevel);
        parsedGlobalInfo.totalPositionAmount = ethers.utils.formatEther(globalInfo.totalPositionAmount["_hex"]);
        parsedGlobalInfo.totalReferrerReward = ethers.utils.formatEther(globalInfo.totalReferrerReward["_hex"]);
        parsedGlobalInfo["userRefData"] = {
            level: userDepartSalesAndLevel[1],
            salesTurnover: ethers.utils.formatEther(userDepartSalesAndLevel[0]),
            childrens: userChildrensAddresses,
        };

        result["userGlobalInfo"] = parsedGlobalInfo;
        result["userChildrenLength"] = Number(childrenLength.toString());
        result["levelToRoundsLength"] = levelToRoundsObj;

        return result;
    }
    parseGlobalInfoOnly(globalInfo) {
        // let levelToRoundsObj = levels.reduce((obj, item, index) => {
        //     return {
        //         ...obj,
        //         [index]: Number(item.toString()),
        //     };
        // }, {});
        const parsedGlobalInfo = {};
        parsedGlobalInfo.boostCredit = ethers.utils.formatEther(globalInfo.boostCredit["_hex"]);
        parsedGlobalInfo.maxChildrenSales = ethers.utils.formatEther(globalInfo.maxChildrenSales);
        parsedGlobalInfo.referrer = globalInfo.referrer;
        parsedGlobalInfo.referrerRewardClaimed = ethers.utils.formatEther(globalInfo.referrerRewardClaimed["_hex"]);
        parsedGlobalInfo.reportedSales = ethers.utils.formatEther(globalInfo.reportedSales);
        parsedGlobalInfo.sales = ethers.utils.formatEther(globalInfo.sales);
        parsedGlobalInfo.salesLevel = Number(globalInfo.salesLevel);
        parsedGlobalInfo.totalPositionAmount = ethers.utils.formatEther(globalInfo.totalPositionAmount["_hex"]);
        parsedGlobalInfo.totalReferrerReward = ethers.utils.formatEther(globalInfo.totalReferrerReward["_hex"]);
        // parsedGlobalInfo["userRefData"] = {
        //     level: userDepartSaleasAndLevel[1],
        //     salesTurnover: ethers.utils.formatEther(userDepartSaleasAndLevel[0]),
        //     childrens: userChildrensAddresses,
        // };

        // result["userChildrenLength"] = Number(childrenLength.toString());
        // result["levelToRoundsLength"] = levelToRoundsObj;

        return parsedGlobalInfo;
    }
    parseUserRounds(userRounds, epochs) {
        const result = {
            0: {},
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
        };
        userRounds.forEach((box, id) => {
            const data = box[0];
            if (box[0].length > 0) {
                result[id][epochs[id]] = [];
                for (let investment of data) {
                    let cur = {};
                    cur.amount = ethers.utils.formatEther(investment.amount["_hex"]);
                    cur.expiryTime = Number(investment.expiryTime.toString());
                    cur.openTime = Number(investment.openTime.toString());
                    cur.incentiveAmount = ethers.utils.formatEther(investment.incentiveAmount["_hex"]);
                    cur.incentiveClaimable = investment.incentiveClaimable;
                    cur.index = investment.index.toString();
                    cur.investReturnRate = investment.investReturnRate.toString();
                    cur.investReturnAmount = ethers.utils.formatEther(investment.investReturnAmount["_hex"]);
                    cur.withdrawnAmount = ethers.utils.formatEther(investment.withdrawnAmount["_hex"]);
                    result[id][epochs[id]].push(cur);
                }
            } else {
                result[id][epochs[id]] = [];
            }
        });

        return result;
    }
    parseUserRound(boxData, box, epoch, addresses = []) {
        const res = {};
        res[box] = {};
        const data = boxData[0];
        if (boxData[0].length > 0) {
            res[box][epoch] = [];
            for (let i = 0; i < data.length; i++) {
                let investment = data[i];

                let cur = {};
                if (addresses.length) {
                    cur["investor"] = addresses[i][0];
                }
                cur.amount = ethers.utils.formatEther(investment.amount["_hex"]);
                cur.expiryTime = Number(investment.expiryTime.toString());
                cur.openTime = Number(investment.openTime.toString());
                cur.incentiveAmount = ethers.utils.formatEther(investment.incentiveAmount["_hex"]);
                cur.incentiveClaimable = investment.incentiveClaimable;
                cur.index = investment.index.toString();
                cur.investReturnRate = investment.investReturnRate.toString();
                cur.investReturnAmount = ethers.utils.formatEther(investment.investReturnAmount["_hex"]);
                cur.withdrawnAmount = ethers.utils.formatEther(investment.withdrawnAmount["_hex"]);
                res[box][epoch].push(cur);
            }
        } else {
            res[box][epoch] = [];
        }

        return res;
    }
    parseSiteData(allEpochs, allRoundInfo, allInvestments) {
        let levelToEpochsObj = allEpochs.reduce((obj, item, index) => {
            return {
                ...obj,
                [index]: Number(item.toString()),
            };
        }, {});
        const result = {};
        result["boxToEpochs"] = levelToEpochsObj;
        const boxLevelToData = {};
        allRoundInfo.forEach((boxData, index) => {
            let obj = {};
            obj["stopLoss"] = boxData.stopLoss;
            obj["totalPositionCount"] = Number(boxData.totalPositionCount.toString());
            obj["currentPositionCount"] = Number(boxData.currentPositionCount.toString());
            obj["head"] = Number(boxData.head.toString());
            obj["totalPositionAmount"] = ethers.utils.formatEther(boxData.totalPositionAmount["_hex"]);
            obj["currentPrincipalAmount"] = ethers.utils.formatEther(boxData.currentPrincipalAmount["_hex"]);
            obj["currentInvestAmount"] = ethers.utils.formatEther(boxData.currentInvestAmount["_hex"]);
            obj["currentIncentiveAmount"] = ethers.utils.formatEther(boxData.currentIncentiveAmount["_hex"]);
            obj["incentiveSnapshot"] = ethers.utils.formatEther(boxData.incentiveSnapshot["_hex"]);
            obj["fundTarget"] = {};
            obj["fundTarget"]["lastCheckTime"] = boxData.fundTarget.lastCheckTime.toString();
            obj["fundTarget"]["amount"] =
                ethers.utils.formatEther(boxData.fundTarget.amount["_hex"]) === "0.0"
                    ? conf[this.currentBlockchain].CONSTANTS.DEFAULT_TARGET_AMOUNTS[index]
                    : ethers.utils.formatEther(boxData.fundTarget.amount["_hex"]);
            obj["fundTarget"]["achievedAmount"] = ethers.utils.formatEther(boxData.fundTarget.achievedAmount["_hex"]);
            obj["investReturnRate"] = this.calculateTargetRate(obj);
            boxLevelToData[index] = obj;
        });
        result["boxLevelToData"] = boxLevelToData;
        result["allInvestments"] = this.parseInvestments(allInvestments, levelToEpochsObj);
        return result;
    }
    parseInvestments(allInvestments, levelToEpochsObj) {
        const resultObj = {
            0: {},
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
        };
        allInvestments.forEach((data, index) => {
            //index is a box type
            const currentEpoch = levelToEpochsObj[index];
            const investmentStats = this.parseUserRound([data[1]], index, currentEpoch, data[0]);

            resultObj[index] = { [levelToEpochsObj[index]]: investmentStats[index][levelToEpochsObj[index]] };
        });

        return resultObj;
    }
    parseInvestmentsPerBoxOrEpoch(boxType, epoch, data) {
        const resultObj = { [boxType]: { [epoch]: [] } };
        const currentEpoch = epoch;
        const investmentStats = this.parseUserRound([data[1]], boxType, currentEpoch, data[0]);

        resultObj[boxType][epoch] = investmentStats[boxType][epoch];

        return resultObj;
    }
    handleError(error) {
        if (error.reason) {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `${error.reason.replace("user", "User").replace("execution reverted: Zero amount", "You do not have an amount to withdraw yet")}`,
            });
        } else if (error.toString().includes("Zero amount")) {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `You do not have an amount to withdraw yet`,
            });
            return;
        } else if (error.toString().includes("cannot estimate gas;")) {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `Ope, an error occured. Try reloading the page. Please contact the   Customer Support if the error persists.`,
            });
            return;
        } else if (error.toString().includes("user rejected transaction")) {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `Transaction was rejected.`,
            });
            return;
        } else if (error.toString().includes("transaction failed")) {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `Transaction failed, please try one more time or contract support.`,
            });
            return;
        } else if (typeof error === "string") {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `${error}`,
            });
            return;
        } else {
            this.context.$store.commit("push_notification", {
                type: "error",
                typeClass: "error",
                message: `${
                    error.data
                        ? error.data.message
                            ? error.data.message
                            : error.message
                        : error.message
                        ? error.message
                        : error.error.toString().slice(0, 150) + "..."
                }`,
            });
            return;
        }
    }
    /* --------> END PARSERS <-------- */

    /* --------> HELPERS <-------- */

    calculateTargetRate(roundInfo) {
        const { amount: targetAmount, achievedAmount } = roundInfo.fundTarget;

        let target = 10000;
        if (achievedAmount <= targetAmount) {
            return 10000 / conf[this.currentBlockchain].CONSTANTS.INVEST_RATE_PERC_ROUNDING;
        }

        const ratioDiff = achievedAmount / targetAmount - 1;
        let times = Math.floor(ratioDiff / 0.2 + 1);

        if (ratioDiff % 0.2 == 0) {
            times -= 1;
        }
        if (times > 14) {
            times = 14;
        }
        return parseFloat((target -= times * 500) / conf[this.currentBlockchain].CONSTANTS.INVEST_RATE_PERC_ROUNDING).toFixed(2);
    }
    async changeNetwork(blockchain) {
        const selectedWallet = window.localStorage.getItem("selectedWallet");

        const networkObject = conf.NETWORK_PARAMS.find((el) => el.symbol === blockchain);

        const params = [
            {
                chainId: networkObject.params.chainId,
                chainName: networkObject.params.chainName,
                nativeCurrency: networkObject.params.nativeCurrency,
                rpcUrls: networkObject.params.rpcUrls,
                blockExplorerUrls: networkObject.params.blockExplorerUrls,
            },
        ];
        const switchParams = [{ chainId: networkObject.params.chainId }];

        if (selectedWallet && (selectedWallet === "metamask" || selectedWallet === "trust")) {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });

                    // const highestId = window.setTimeout(() => {
                    //     for (let i = highestId; i >= 0; i--) {
                    //         // console.log(i);
                    //         window.clearInterval(i);
                    //     }
                    // }, 0);
                    // console.log("chain changed");
                } catch (switchError) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902 || switchError?.code?.toString() === "-32603") {
                        try {
                            await window.ethereum.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });

                            // const highestId = window.setTimeout(() => {
                            //     for (let i = highestId; i >= 0; i--) {
                            //         // console.log(i);
                            //         window.clearInterval(i);
                            //     }
                            // }, 0);
                        } catch (addError) {
                            console.log(addError);
                        }
                    } else if (switchError?.message.toString().includes("already pending")) {
                        throw new Error("already pending");
                    }

                    // handle other "switch" errors
                }
            } else {
                alert("Please install metamask wallet extension");
            }
        } else if (selectedWallet && selectedWallet === "walletconnect") {
            if (!this.provider) {
                await timer(500);
            }
            try {
                //request method may be in different provider objects
                if (this.provider.request) {
                    await this.provider.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });
                } else if (this.provider.provider && this.provider.provider.request) {
                    await this.provider.provider.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });
                } else if (this.providerInstance && this.providerInstance.request) {
                    await this.providerInstance.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });
                }

                // const highestId = window.setTimeout(() => {
                //     for (let i = highestId; i >= 0; i--) {
                //         // console.log(i);
                //         window.clearInterval(i);
                //     }
                // }, 0);
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902 || switchError?.code?.toString() === "-32603" || switchError.toString().includes("Unrecognized chain")) {
                    try {
                        if (this.provider.request) {
                            await this.provider.provider.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });
                        } else if (this.provider.provider && this.provider.provider.request) {
                            await this.provider.provider.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });
                        } else if (this.providerInstance && this.providerInstance.request) {
                            await this.providerInstance.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });
                        }

                        // const highestId = window.setTimeout(() => {
                        //     for (let i = highestId; i >= 0; i--) {
                        //         // console.log(i);
                        //         window.clearInterval(i);
                        // }
                        // }, 0);
                    } catch (addError) {
                        console.log(addError);
                    }
                }
                if (switchError.toString().includes(`Cannot read properties of undefined (reading 'provider')`)) {
                    this.context.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "warning",
                        message: `Please change network in your connected wallet`,
                    });
                }
                console.log(switchError);

                // handle other "switch" errors
            }
        } else if (selectedWallet && selectedWallet === "email") {
            localStorage.setItem("desiredChain", Number(networkObject.params.chainId));
            window.location.reload();
            return;
        }
    }

    async estimateGas(methodName, methodParams, value) {
        if (!value) {
            value = 0;
        }
        let gas = await this[`mainContract_${this.currentBlockchain}`].estimateGas[methodName](...methodParams, { value: value });
        gas = gas.toString();
        return gas;
    }
    /* --------> END HELPERS <-------- */
}
