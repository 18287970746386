export const mainContractAbi = [
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256[]",
                name: "positionIndexes",
                type: "uint256[]",
            },
        ],
        name: "batchClaimPositionIncentiveReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256[]",
                name: "positionIndexes",
                type: "uint256[]",
            },
        ],
        name: "batchClosePositions",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "users",
                type: "address[]",
            },
        ],
        name: "batchReportSales",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "users",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "referrers",
                type: "address[]",
            },
            {
                internalType: "uint8[]",
                name: "salesLevels",
                type: "uint8[]",
            },
        ],
        name: "batchSetReferrerInfo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
        ],
        name: "claimReferrerReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "positionIndex",
                type: "uint256",
            },
        ],
        name: "closePosition",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "dropTempAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_platformAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "_tempAdmin",
                type: "address",
            },
            {
                internalType: "address",
                name: "_operator",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "IncentiveClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "referrer",
                type: "address",
            },
        ],
        name: "NewReferrer",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "ledgeType",
                type: "uint256",
            },
        ],
        name: "NewRound",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "targetEpoch",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "targetRate",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
            {
                internalType: "bool",
                name: "useBoost",
                type: "bool",
            },
        ],
        name: "openPosition",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "ledgeType",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "positionIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "PositionClosed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "ledgeType",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "positionIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "PositionOpened",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "rewardType",
                type: "uint256",
            },
        ],
        name: "ReferrerRewardAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "ReferrerRewardClaimed",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "retrieve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint8",
                name: "level",
                type: "uint8",
            },
        ],
        name: "SalesLevelUpdated",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "setPause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint8[]",
                name: "typeDays",
                type: "uint8[]",
            },
            {
                internalType: "uint16[]",
                name: "stock",
                type: "uint16[]",
            },
        ],
        name: "setStock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_operator",
                type: "address",
            },
        ],
        name: "transferOperator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "BOOST_INVEST_RETURN_RATE",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "children",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "currentEpochs",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "DEFAULT_INVEST_RETURN_RATE",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "DEFAULT_TARGET_AMOUNTS",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "gamePaused",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "boxType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "cursor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
        ],
        name: "getAllInvestmentsData",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "userPositionIndex",
                        type: "uint256",
                    },
                ],
                internalType: "struct Avatar.LinkedPosition[]",
                name: "",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "openTime",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "expiryTime",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "investReturnRate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "withdrawnAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "incentiveAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "investReturnAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "index",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "incentiveClaimable",
                        type: "bool",
                    },
                ],
                internalType: "struct Avatar.PositionInfo[]",
                name: "",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "totalPositionAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalPositionCount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "currentIncentiveAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "incentiveSnapshot",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "head",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "stopLoss",
                        type: "bool",
                    },
                ],
                internalType: "struct Avatar.ViewRoundData",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
        ],
        name: "getBucketInfo",
        outputs: [
            {
                internalType: "uint8[]",
                name: "",
                type: "uint8[]",
            },
            {
                internalType: "uint16[]",
                name: "",
                type: "uint16[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "cursor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
        ],
        name: "getChildren",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getChildrenLength",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getLedgerRoundToUserRoundIndex",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "cursor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
        ],
        name: "getLinkedPositionInfo",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "userPositionIndex",
                        type: "uint256",
                    },
                ],
                internalType: "struct Avatar.LinkedPosition[]",
                name: "",
                type: "tuple[]",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getUserDepartSalesAndLevel",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "cursor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
        ],
        name: "getUserRoundLedgers",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "openTime",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "expiryTime",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "investReturnRate",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "withdrawnAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "incentiveAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "investReturnAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "index",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "incentiveClaimable",
                        type: "bool",
                    },
                ],
                internalType: "struct Avatar.PositionInfo[]",
                name: "",
                type: "tuple[]",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getUserRoundLedgersLength",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "cursor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "size",
                type: "uint256",
            },
        ],
        name: "getUserRounds",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "epoch",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalPositionAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "currentPrincipalAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalWithdrawnAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalIncentiveClaimedAmount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalClosedPositionCount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "returnRateBoostedAmount",
                        type: "uint256",
                    },
                ],
                internalType: "struct Avatar.UserRoundInfo[]",
                name: "",
                type: "tuple[]",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "ledgerType",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getUserRoundsLength",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "INCENTIVE_RATIO",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "INVEST_RATIO",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "ledgerBucketStock",
        outputs: [
            {
                internalType: "uint16",
                name: "currentBucketStock",
                type: "uint16",
            },
            {
                internalType: "uint256",
                name: "stockSize",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MAX_INVEST",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MAX_SEARCH_DEPTH",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MIN_INVEST",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "operator",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PLATFORM_RATIO",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "platformAddress",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PRICE_PRECISION",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PRINCIPAL_RATIO",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "RANKED_INCENTIVE",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "REFERRER_RATIO",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "roundInfos",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "lastCheckTime",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "achievedAmount",
                        type: "uint256",
                    },
                ],
                internalType: "struct Avatar.FundTarget",
                name: "fundTarget",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "totalPositionAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "currentPrincipalAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "currentInvestAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalPositionCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "currentPositionCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "currentIncentiveAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "incentiveSnapshot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "head",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "stopLoss",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "roundLedgers",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "openTime",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "expiryTime",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "investReturnRate",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "withdrawnAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "incentiveAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "investReturnAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "incentiveClaimable",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "tempAdmin",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "TIME_UNIT",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "userGlobalInfos",
        outputs: [
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "totalReferrerReward",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "referrerRewardClaimed",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "boostCredit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "maxChildrenSales",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "sales",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalPositionAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "reportedSales",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "salesLevel",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "userRoundsInfos",
        outputs: [
            {
                internalType: "uint256",
                name: "epoch",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalPositionAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "currentPrincipalAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalWithdrawnAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalIncentiveClaimedAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalClosedPositionCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "returnRateBoostedAmount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
