<script setup>
    import IconArrowDown from "../components/IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="container">
        <div class="container-card">
            <div class="card-content">
                <div class="col">
                    <div v-if="$route.name === 'Home'" class="card-header">
                        <div class="h1">Magic Box</div>
                    </div>
                    <!-- {{ $route.name }} -->

                    <template v-if="$route.name === 'MagicBoxes'">
                        <div class="card-selected-box">
                            <div class="row">
                                <div class="col col-headline">
                                    <div class="h1">{{ getSelectedPlan.PLAN_NAME }}</div>
                                    <div class="h3">Current Magic Box</div>
                                </div>
                                <div class="col col-icon">
                                    <i class="box-icon box-1"></i>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="card-input-wrap">
                        <div class="row">
                            <div class="headline row">
                                <div class="credit">
                                    Boost credit:
                                    <div class="card-icon">
                                        <img src="../assets/images/network/bnb.png" alt="" />
                                    </div>
                                    <span>{{ userData && userData.boostCredit ? parseFloat(Number(userData.boostCredit).toFixed(4)) : "0.00" }}</span>
                                </div>

                                <div class="headline-btn">
                                    <button :disabled="!isBoostAvailable" class="btn btn-boosted" @click="useBoost = !useBoost">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.3347 13.3333C9.89365 14.3882 9.0549 15.227 8.00002 15.668C6.94514 15.227 6.1064 14.3882 5.66535 13.3333H7.17202C7.38935 13.6593 7.67002 13.9413 8.00002 14.162C8.33002 13.942 8.61069 13.6593 8.82869 13.3333H10.3347ZM12 9.87L13.3334 11.382V12.6667H2.66669V11.382L4.00002 9.87V6C4.00002 3.678 5.66935 1.702 8.00002 0.970001C10.3307 1.702 12 3.678 12 6V9.87ZM11.5134 11.3333L10.6667 10.3733V6C10.6667 4.45467 9.62002 3.04667 8.00002 2.38667C6.38002 3.04667 5.33335 4.454 5.33335 6V10.3733L4.48669 11.3333H11.5134ZM8.00002 7.33333C7.6464 7.33333 7.30726 7.19286 7.05721 6.94281C6.80716 6.69276 6.66669 6.35362 6.66669 6C6.66669 5.64638 6.80716 5.30724 7.05721 5.05719C7.30726 4.80714 7.6464 4.66667 8.00002 4.66667C8.35364 4.66667 8.69278 4.80714 8.94283 5.05719C9.19288 5.30724 9.33335 5.64638 9.33335 6C9.33335 6.35362 9.19288 6.69276 8.94283 6.94281C8.69278 7.19286 8.35364 7.33333 8.00002 7.33333Z"
                                                fill="#818899"
                                            />
                                        </svg>
                                        <span v-if="useBoost">Boosted</span>
                                        <span v-else>Boost</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-input"
                                :class="{'card-input-error': 
                                    showInvestAmountInputError
                                    || showDepAmountMoreThanBoost}"
                            >
                                <input placeholder="Enter # of seeds" v-model="amount" />
                                <div class="card-icon">
                                    <img src="../assets/images/network/bnb.png" alt="" />
                                </div>
                            </div>
                            <p v-if="showInvestAmountInputError" class="p-error">Please enter valid amount</p>
                            <p v-if="showDepAmountMoreThanBoost" class="p-error">Entered amount is greater that your boost amount.</p>
                        </div>
                        <div class="row">
                            <div @click="setInvestAmount($event)" class="speed-input">
                                <button value="0" :class="{ active: depFraction == '0' }">Min</button>
                                <button value="1" :class="{ active: depFraction == '1' }">1/2</button>
                                <button value="2" :class="{ active: depFraction == '2' }">Max</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-input-wrap">
                        <template v-if="$route.name === 'Home'">
                            <div class="row">
                                <div class="headline">Magic box type</div>
                            </div>

                            <div class="row">
                                <!-- <div class="card-select active"> -->
                                <div class="card-select" :class="{ active: openList }">
                                    <div class="drop-down-bg-click" @click="openList = false"></div>
                                    <button class="btn-card-select" @click="openList = !openList">
                                        <span class="card-select-value"> {{ getSelectedPlan.PLAN_NAME }} </span>
                                        <div class="card-icon icon-arrow">
                                            <IconArrowDown />
                                        </div>
                                    </button>
                                    <div class="items-wrap">
                                        <div class="items-container">
                                            <ul @click="selectNewBox($event)">
                                                <li v-for="plan in getPossiblePlans" @click="openList = false" :value="plan.PLAN_ID">{{ plan.PLAN_NAME }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="row row-rate">
                            <div>Seed growth rate</div>
                            <div>{{ getTargetRate }}%</div>
                        </div>
                    </div>

                    <div v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'" class="card-input-wrap">
                        <div class="row">
                            <div class="headline">My sacred tree:</div>
                        </div>

                        <div class="row">
                            <div class="card-input"
                                    :class="{'card-input-error': 
                                    showReferrerInvalidError }"
                            >
                                <input placeholder="Enter the invitation address" v-model="referrer" />
                            </div>
                            <p v-if="showReferrerInvalidError" class="p-error">Please enter the correct invitation address</p>
                        </div>
                    </div>
                </div>
                <div class="card-main-btn col">
                    <p>You are linked to your sacred tree</p>
                    <button
                        v-if="!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000'"
                        class="btn"
                        @click="$emit('connectWallet')"
                    >
                        Connect Wallet
                    </button>
                    <button v-else @click="openPosition()" class="btn">Open Magic Box</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import config from "../../config.json";
    var conf = config[config.env];

    export default {
        props: ["selectedBoxProp"],
        data() {
            return {
                openList: false,
                selectedBox: 0,
                amount: "",
                useBoost: false,
                depFraction: "",
                referrer: "",
                showReferrerInvalidError: false,
                showInvestAmountInputError: false,
                showDepAmountMoreThanBoost: false,
            };
        },

        computed: {
            ...mapState(["boxToEpochs", "boxLevelToData", "currentAddress", "currentBlockchain", "preselectedChain", "userData", "userCoinBalance"]),
            getPossiblePlans() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return conf[chainId].PLANS;
            },
            getSelectedPlan() {
                if (this.$route.name !== "Home") {
                    return this.getPossiblePlans[this.selectedBoxProp];
                }
                return this.getPossiblePlans[this.selectedBox];
            },
            isBoostAvailable() {
                let boostAmount = Number(this.userData?.boostCredit) || 0;
                return !!boostAmount;
            },
            getTargetRate() {
                if (!this.boxLevelToData) return 0;
                if (this.useBoost) {
                    let chainId;
                    if (!this.currentBlockchain) {
                        chainId = this.preselectedChain;
                    } else {
                        chainId = this.currentBlockchain;
                    }
                    return this.boxLevelToData[this.selectedBox].investReturnRate + conf[chainId].CONSTANTS.BOOST_INVEST_RETURN_RATE;
                }
                return this.boxLevelToData[this.selectedBox].investReturnRate;
            },
        },
        mounted() {
            if (this.$route.name !== "Home") {
                this.selectedBox = this.selectedBoxProp;
            }
        },

        methods: {
            setInvestAmount(event) {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                this.depFraction = event.target.value;
                this.amount =
                    event.target.value === "0"
                        ? conf[chainId].MIN_INVEST
                        : event.target.value === "2"
                        ? conf[chainId].MAX_INVEST
                        : conf[chainId].MAX_INVEST / 2;
            },
            selectNewBox(event) {
                this.selectedBox = event.target.value;
            },
            async openPosition() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }

                try {
                    if (this.showDepAmountMoreThanBoost && this.useBoost) {
                        this.$store.commit("push_notification", {
                            type: "error",
                            typeClass: "error",
                            message: `Please enter amount that is within your boost amount.`,
                        });
                        return;
                    }
                    if (!this.amount) {
                        this.showInvestAmountInputError = true;
                        this.$store.commit("push_notification", {
                            type: "error",
                            typeClass: "error",
                            message: `Please enter amount.`,
                        });
                        return;
                    }
                    if (Number(this.amount) < conf[chainId].MIN_INVEST) {
                        this.$store.commit("push_notification", {
                            type: "error",
                            typeClass: "error",
                            message: `Amount is less than minimum investment.  Amount should be within ${conf[chainId].MIN_INVEST} and ${conf[chainId].MAX_INVEST} BNB.`,
                        });
                        return;
                    }
                    if (Number(this.amount) > conf[chainId].MAX_INVEST) {
                        this.$store.commit("push_notification", {
                            type: "warning",
                            typeClass: "warning",
                            message: `Amount is bigger than maximum investment. Amount should be within ${conf[chainId].MIN_INVEST} and ${conf[chainId].MAX_INVEST} BNB.`,
                        });
                        return;
                    }
                    if (Number(this.amount) > Number(this.userCoinBalance)) {
                        this.$store.commit("push_notification", {
                            type: "error",
                            typeClass: "error",
                            message: `You BNB balance too low.`,
                        });
                        return;
                    }

                    let targetEpoch = this.boxToEpochs[this.selectedBox] || 0;
                    let targetRate = this.boxLevelToData[this.selectedBox].investReturnRate;
                    let useBoost = this.useBoost;
                    let amount = this.amount;
                    let referrer = this.referrer;

                    let tx = await this.$root.core.openPosition(this.selectedBox, targetEpoch, targetRate, referrer, useBoost, amount);
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "warning",
                        message: `Processing your transaction...`,
                    });
                    await tx.wait(3);
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: `Your transaction has been completed.`,
                    });
                } catch (error) {
                    console.log(error);
                    this.$root.core.handleError(error);
                }
            },
        },
        watch: {
            referrer: function (newVal) {
                if (newVal.length !== 42) {
                    this.showReferrerInvalidError = true;
                } else {
                    this.showReferrerInvalidError = false;
                }
            },
            amount: function (newVal) {
                if (newVal > 0) {
                    this.showInvestAmountInputError = false;
                    this.showDepAmountMoreThanBoost = false;
                    if (this.useBoost && Number(newVal) > Number(this.userData?.boostCredit)) {
                        this.showDepAmountMoreThanBoost = true;
                    }
                } else {
                    this.showInvestAmountInputError = true;
                }
            },
            selectedBoxProp: function (newVal) {
                if (this.$route.name !== "Home") {
                    this.selectedBox = newVal;
                }
            },
            useBoost: function (newVal) {
                if (newVal) {
                    this.amount = this.userData?.boostCredit;
                }
            },
        },
    };
</script>
