<template>
  <div class="mobile-menu">
    <div class="mobile-menu-container">
        <div class="col">
            <div class="row">
                <div class="row-drop-downs">
                    <!-- <SelectLang /> -->
                    <!-- <SelectNetwork /> -->

                    <div class="drop-down drop-down-network" >
                        <div class="drop-down-bg-click"></div>
                        <div class="btn-drop-down"
                        >
                            <i class="icon-box">
                                <img src="../../assets/images/network/bnb.png" alt="" />
                            </i>
                            <span>BNB</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="row-wallet">
                    <ConnectWallet @connectWallet="$emit('connectWallet')" />
                </div>
            </div>
            <div class="row">
                <nav class="col col-nav">
                    
                    <nav class="header-nav col">
                        <RouterLink to="/" exact-active-class="active">
                            <span>Home</span>
                        </RouterLink>
                        <RouterLink to="/boxes" active-class="active">
                            <span>Pandora</span>
                        </RouterLink>
                        <template  
                            v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                        >
                            <RouterLink to="/tribe" active-class="active">
                                <span>Tribe</span>
                            </RouterLink>
                        </template>
                    </nav>
                </nav>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SelectNetwork from './SelectNetwork.vue'
import ConnectWallet from './ConnectWallet.vue'

export default {
    components:{
        SelectNetwork,
        ConnectWallet,
    },
    computed: {
        ...mapState(["currentAddress"]),
    },
}
</script>