<script setup>
    import ConnectWallet from "../Header/ConnectWallet.vue";
    import SelectNetwork from "../Header/SelectNetwork.vue";
    import SelectLang from "../Header/SelectLang.vue";
</script>

<template>
    <header :class="{ 'opened-menu': mobileMenu == true }">
        <div v-if="mobileMenu == true" 
            @click="mobileMenu = false"
            class="opened-menu-under"></div>
        <div class="container">
            <div class="row header-desktop">
                <router-link class="main-logo" to="/">
                    <img src="../../assets/logo.svg" alt="main logo" />
                </router-link>

                <nav class="header-nav">
                    <router-link :to="{ name: 'Home' }" exact-active-class="active">
                        <span>Home</span>
                        <span>Home</span>
                    </router-link>
                    <router-link :to="{ name: 'Pandora' }" active-class="active">
                        <span>Pandora</span>
                        <span>Pandora</span>
                    </router-link>
                    <template  
                        v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                    >
                        <router-link :to="{ name: 'Tribe' }" active-class="active">
                            <span>Tribe</span>
                            <span>Tribe</span>
                        </router-link>
                    </template>
                </nav>

                <div class="header-dashboard row">
                    <!-- <SelectLang /> -->
                    <!-- <SelectNetwork /> -->

                    <div class="drop-down drop-down-network" >
                        <div class="drop-down-bg-click"></div>
                        <div class="btn-drop-down"
                        >
                            <i class="icon-box">
                                <img src="../../assets/images/network/bnb.png" alt="" />
                            </i>
                            <span>BNB</span>
                        </div>
                    </div>

                    <ConnectWallet @connectWallet="$emit('connectWallet')" />
                </div>
            </div>
            <div class="col header-mobile-wrapper">
                <div class="row header-mobile">
                    <router-link class="main-logo" to="/">
                        <img src="../../assets/logo.svg" alt="main logo" />
                    </router-link>

                    <div class="header-dashboard row">
                        <ConnectWallet @connectWallet="$emit('connectWallet')" />

                        <button class="btn-mob-menu" :class="{ active: mobileMenu == true }" @click="mobileMenu = !mobileMenu"></button>
                    </div>
                </div>
                <MobileMenu :class="{ active: mobileMenu == true }" />
            </div>
        </div>
    </header>
</template>

<script>
    import { mapState } from "vuex";
    import MobileMenu from "./MobileMenu.vue";

    export default {
        data() {
            return {
                mobileMenu: false,
            };
        },
        components: {
            MobileMenu,
        },
        computed: {
            ...mapState(["currentAddress"]),
        },
    };
</script>
