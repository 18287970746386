
<script setup>
import IconArrowDown from '../IconsComponent/IconArrowDown.vue';
</script>

<template>
    <div class="drop-down drop-down-lang"
        :class="{active : dropDown}"
    >
    <!-- <div class="drop-down drop-down-lang active"> -->
        <div class="drop-down-bg-click" @click="dropDown = false"></div>

        <button class="btn-drop-down"
            @click="dropDown = !dropDown"
        >
            <i class="icon-box">
                <img src="../../assets/images/lang/4.png" alt="" />
            </i>
            <span>{{ chosenLang }} </span>
            <div class="icon-arrow">
                <IconArrowDown />       
            </div> 
        </button>

        <div class="items-wrap">
            <div class="items-container">
                <ul>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/lang/1.png" alt="" />
                        </i>
                        简体中文
                    </li>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/lang/2.png" alt="" />
                        </i>
                        日本語
                    </li>
                    <li>
                        <i class="li-icon">
                            <img src="../../assets/images/lang/3.png" alt="" />
                        </i>
                        한국어
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script >
export default {
    data() {
        return {
            chosenLang: 'En',
            dropDown: false,
        }
    },

}
</script>