<template>
    <div class="app">
        <NotificationList />
        <div class="app-wrap">
            <HeaderComponent @connectWallet="setWalletOption()" />
            <router-view @connectWallet="setWalletOption()" />
        </div>
        <FooterComponent />
    </div>
</template>

<script>
    import FooterComponent from "./components/FooterComponent.vue";
    import HeaderComponent from "./components/Header/HeaderComponent.vue";
    import PushComponent from "./components/Push.vue";
    import ReferralController from "./core/ReferralController";

    import Core from "./core/core";
    import config from "../config.json";

    import NotificationList from "./components/NotificationList.vue";
    var conf = config[config.env];

    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    export default {
        name: "App",
        components: {
            FooterComponent,
            HeaderComponent,
            PushComponent,
            NotificationList,
        },
        data() {
            return {
                showWalletOptions: false,
                supportedChain: true,
                noWallet: false,
                showProceedToMetamaskModal: false,
                currentAccount: "0x0000000000000000000000000000000000000000",
            };
        },
        async created() {
            if (config.env === "mainnet") {
                //here goes mainnet scripts: fbpixel, gtm, GA, etc
                // const script = document.createElement("script");
                // script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WK9GRM8');`;
                // document.head.appendChild(script);
                // const noScript = document.createElement("noscript");
                // var iFrame = document.createElement("iframe");
                // iFrame.setAttribute("src", "https://www.googletagmanager.com/ns.html?id=GTM-WK9GRM8");
                // iFrame.setAttribute("height", "0");
                // iFrame.setAttribute("width", "0");
                // iFrame.setAttribute("style", "display:none;visibility:hidden");
                // noScript.appendChild(iFrame);
                // document.body.appendChild(noScript);
            } else if (config.env === "testnet") {
                const meta = document.createElement("meta");
                meta.setAttribute("name", "robots");
                meta.setAttribute("content", "noindex");
                document.head.appendChild(meta);
                //             const script = document.createElement("script");
                //             script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                // })(window,document,'script','dataLayer','GTM-PLQR5XC');`;
                //             document.head.appendChild(script);
                //             const noScript = document.createElement("noscript");
                //             var iFrame = document.createElement("iframe");
                //             iFrame.setAttribute("src", "ttps://www.googletagmanager.com/ns.html?id=GTM-PLQR5XC");
                //             iFrame.setAttribute("height", "0");
                //             iFrame.setAttribute("width", "0");
                //             iFrame.setAttribute("style", "display:none;visibility:hidden");
                //             noScript.appendChild(iFrame);
                //             document.body.appendChild(noScript);
            }
        },

        mounted() {
            document.body.classList.remove("opened-mob-menu");
            ReferralController.setReferrerAddressIfExists(this);
            document.body.style.overflow = "auto";
            let connectAttemptsCounter = 0;
            let _this = this;
            const walletOption = _this.getWalletOption();
            setTimeout(async function initContract() {
                try {
                    // _this.$root.core = new Core(_this, )
                    if (walletOption) {
                        if ((walletOption === "metamask" || walletOption === "trust") && !window.ethereum) {
                            throw Error("No Metamask extension found");
                        }
                        if (window.localStorage.getItem("selectedWallet") === "metamask" || window.localStorage.getItem("selectedWallet") === "trust") {
                            //won't commit address without these checks
                            if (window.ethereum) {
                                window.ethereum.on("chainChanged", async (_chainId) => {
                                    if (!document.hidden) {
                                        _chainId = Number(_chainId);
                                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(_chainId) < 0) {
                                            alert(
                                                `${
                                                    conf.BLOCKCHAINS[_chainId] ? conf.BLOCKCHAINS[_chainId] : "Current chain"
                                                }  is not suppoted. Please accept switching to ${conf.PRIMARY_BLOCKCHAIN.name}, ${_chainId}`
                                            );
                                            _this.supportedChain = false;
                                            await _this.$root.core.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                                            window.location.reload();
                                            return;
                                        }
                                        await timer(1000);
                                        window.location.reload();
                                        //TODO: START LOOP
                                    } else {
                                        const res = confirm(
                                            `${
                                                conf.BLOCKCHAINS[_chainId] ? conf.BLOCKCHAINS[_chainId] : "Current chain"
                                            }  is not suppoted. Please accept switching to ${conf.PRIMARY_BLOCKCHAIN.name}, ${_chainId}`
                                        );
                                        if (res) {
                                            await _this.$root.core.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                                            window.location.reload();
                                            return;
                                        }
                                    }
                                });
                                window.ethereum.on("isConnected", () => window.location.reload());
                            }
                            if (window.ethereum && !window.ethereum.chainId) {
                                throw new Error("no chainId");
                            }
                            if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.ethereum.chainId)) < 0) {
                                alert(`Change your wallet extension to ${conf.PRIMARY_BLOCKCHAIN.name}, ${Number(window.ethereum.chainId)}`);
                                _this.supportedChain = false;
                                await _this.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                            }
                            let currentAccount = localStorage.getItem("address");
                            _this.currentAccount = currentAccount;
                            _this.$store.commit("setCurrentAddress", currentAccount);
                            _this.setWalletOption(_this.getWalletOption());
                            _this.$root.core = new Core(_this);
                            if (_this.$root.core === undefined) {
                                throw Error();
                            }
                            if (currentAccount) {
                                _this.$root.core.getUserStatsLoop(currentAccount, true);
                                _this.$root.core.getSiteData(true);
                                //TODO: START LOOP
                            } else {
                                _this.$root.core.getSiteData();
                                // _this.$root.core.setupDefault();
                                //TODO: START LOOP
                            }
                        }
                        //  else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                        //     const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                        //     let currentAccount = localStorage.getItem("address");
                        //     _this.currentAccount = currentAccount;
                        //     _this.$store.commit("setCurrentAddress", currentAccount);
                        //     _this.setWalletOption(_this.getWalletOption());
                        //     _this.$root.core = new Core(_this);
                        //     if (_this.$root.core === undefined) {
                        //         throw Error();
                        //     } else {
                        //         _this.$root.core.providerInstance.on("chainChanged", async (_chainId) => {
                        //             if (!document.hidden) {
                        //                 _chainId = Number(_chainId);
                        //                 if (conf.SUPPORTED_BLOCKCHAINS.indexOf(_chainId) < 0) {
                        //                     alert(
                        //                         `${
                        //                             conf.BLOCKCHAINS[_chainId] ? conf.BLOCKCHAINS[_chainId] : "Current chain"
                        //                         }  is not suppoted. Please accept switching to ${conf.PRIMARY_BLOCKCHAIN.name}`
                        //                     );
                        //                     _this.supportedChain = false;
                        //                     await _this.$root.core.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                        //                     return;
                        //                 }
                        //                 window.location.reload();
                        //             } else {
                        //                 const res = confirm(
                        //                     `${
                        //                         conf.BLOCKCHAINS[_chainId] ? conf.BLOCKCHAINS[_chainId] : "Current chain"
                        //                     }  is not suppoted. Please accept switching to ${conf.PRIMARY_BLOCKCHAIN.name}`
                        //                 );
                        //                 if (res) {
                        //                     await _this.$root.core.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                        //                     window.location.reload();
                        //                     return;
                        //                 }
                        //             }
                        //             //TODO: START LOOP
                        //         });
                        //         _this.$root.core.providerInstance.on("disconnect", (code, reason) => {
                        //             console.log(`connector.on("disconnect")`, code, reason);
                        //             _this.$root.core.onDisconnect();
                        //         });
                        //     }
                        //     if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(WC_Obj.chainId)) < 0) {
                        //         alert("Change your wallet extension to Polygon network");
                        //         _this.supportedChain = false;
                        //         await _this.$root.core.changeNetwork(conf.PRIMARY_BLOCKCHAIN.symbol);
                        //     }
                        //     if (currentAccount) {
                        //         //TODO: START LOOP
                        //         _this.$root.core.getUserStatsLoop(_this.currentAccount, true);
                        //         _this.$root.core.getSiteData(true);
                        //     } else {
                        //         //TODO: START LOOP
                        //         _this.$root.core.getSiteData();
                        //         _this.$root.core.setupDefault();
                        //     }
                        //     // _this.showWalletOptions = true;
                        // }
                        else {
                            _this.noWallet = true;
                            _this.$root.core = new Core(_this);
                            console.log("here?");
                            _this.$root.core.getSiteData();
                            // _this.$root.core.setupDefault();
                            //TODO: START LOOP
                        }
                    } else {
                        _this.$root.core = new Core(_this);
                        _this.$root.core.getSiteData();
                        // _this.$root.core.setupDefault();
                        //TODO: START LOOP
                    }
                } catch (ex) {
                    console.log(ex);
                    if (ex.message === "no chainId") {
                        setTimeout(initContract, 300);
                        return;
                    }
                    connectAttemptsCounter++;
                    if (connectAttemptsCounter > 5 && ex.message == "No Metamask extension found") {
                        _this.noWallet = true;
                        _this.$root.core = new Core(_this);
                        _this.$root.core.getSiteData();
                        // _this.$root.core.setupDefault();
                        return;
                    }
                    // if (window.localStorage.getItem("selectedWallet") !== "walletconnect") {
                    //     setTimeout(initContract, 300);
                    // }
                }
            }, 400);
            window.scrollTo(0, 0);
        },

        methods: {
            checkWalletOption(wallet) {
                let _this = this;
                if (wallet === "metamask" || wallet === "trust") {
                    window.ethereum
                        .request({ method: "eth_accounts" })
                        .then(handleAccountsChanged)
                        .catch((err) => {
                            localStorage.removeItem("address");
                        });
                    window.ethereum.on("accountsChanged", handleAccountsChanged);
                    function handleAccountsChanged(accounts) {
                        if (accounts.length > 0) {
                            _this.walletUnlocked = true;
                        }
                    }
                }
            },
            setWalletOption(wallet = "metamask") {
                let _this = this;
                this.selectedWallet = wallet;
                if (wallet) {
                    localStorage.setItem("selectedWallet", wallet);
                    localStorage.setItem("hasBeenLoggedIn", "true");
                }
                if (window.ethereum && (this.selectedWallet === "metamask" || this.selectedWallet === "trust")) {
                    window.ethereum
                        .request({ method: "eth_requestAccounts" })
                        .then(handleAccountsChanged)
                        .catch((err) => {
                            console.log(err);
                            localStorage.removeItem("address");
                        });
                    // Note that this event is emitted on page load.
                    // If the array of accounts is non-empty, you're already
                    // connected.
                    window.ethereum.on("accountsChanged", handleAccountsChanged);
                    // For now, 'eth_accounts' will continue to always return an array
                    function handleAccountsChanged(accounts) {
                        let currentAccount = localStorage.getItem("address");
                        if (accounts.length === 0) {
                            _this.walletUnlocked = false;
                            localStorage.removeItem("address");
                            _this.$store.commit("setCurrentAddress", "");
                            // MetaMask is locked or the user has not connected any accounts
                            // alert('Please connect to MetaMask.');
                        } else if (accounts[0] !== currentAccount) {
                            currentAccount = accounts[0];
                            localStorage.setItem("address", currentAccount);
                            // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                            location.reload();
                        } else if (accounts.length > 0) {
                            _this.walletUnlocked = true;
                        }
                    }
                } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                    if (!this.$root.core) {
                    } else {
                        this.$root.core.init();
                    }
                } else {
                    _this.noWallet = true;
                }
            },
            getWalletOption() {
                const selWallet = localStorage.getItem("selectedWallet");
                if (!selWallet) {
                    this.showWalletOptions = true;
                    return null;
                } else {
                    return selWallet;
                }
            },
            async changeNetwork(blockchain) {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                const networkObject = conf.NETWORK_PARAMS.find((el) => el.symbol === blockchain);
                const params = [
                    {
                        chainId: networkObject.params.chainId,
                        chainName: networkObject.params.chainName,
                        nativeCurrency: networkObject.params.nativeCurrency,
                        rpcUrls: networkObject.params.rpcUrls,
                        blockExplorerUrls: networkObject.params.blockExplorerUrls,
                    },
                ];
                const switchParams = [{ chainId: networkObject.params.chainId }];
                if (selectedWallet && (selectedWallet === "metamask" || selectedWallet === "trust")) {
                    if (window.ethereum) {
                        try {
                            await window.ethereum.request({
                                method: "wallet_switchEthereumChain",
                                params: switchParams,
                            });
                            // const highestId = window.setTimeout(() => {
                            //     for (let i = highestId; i >= 0; i--) {
                            //         // console.log(i);
                            //         window.clearInterval(i);
                            //     }
                            // }, 0);
                            console.log("chain changed");
                        } catch (switchError) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (switchError.code === 4902 || switchError?.code?.toString() === "-32603") {
                                try {
                                    await window.ethereum.request({
                                        method: "wallet_addEthereumChain",
                                        params: params,
                                    });
                                    // const highestId = window.setTimeout(() => {
                                    //     for (let i = highestId; i >= 0; i--) {
                                    //         // console.log(i);
                                    //         window.clearInterval(i);
                                    //     }
                                    // }, 0);
                                } catch (addError) {
                                    console.log(addError);
                                }
                            }
                            // handle other "switch" errors
                        }
                    } else {
                        alert("Please install metamask wallet extension");
                    }
                } else if (selectedWallet && selectedWallet === "walletconnect") {
                    try {
                        await this.provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: switchParams,
                        });
                        // const highestId = window.setTimeout(() => {
                        //     for (let i = highestId; i >= 0; i--) {
                        //         // console.log(i);
                        //         window.clearInterval(i);
                        //     }
                        // }, 0);
                    } catch (switchError) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === 4902 || switchError?.code?.toString() === "-32603" || switchError.toString().includes("Unrecognized chain")) {
                            try {
                                await this.provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: params,
                                });
                                // const highestId = window.setTimeout(() => {
                                //     for (let i = highestId; i >= 0; i--) {
                                //         // console.log(i);
                                //         window.clearInterval(i);
                                // }
                                // }, 0);
                            } catch (addError) {
                                console.log(addError);
                            }
                        }
                        console.log(switchError);
                        // handle other "switch" errors
                    }
                }
            },
        },
    };
</script>
