<template>
    <main class="main-pandora">
        <section class="section-switcher">
            <PandoraSwitcher />
        </section>

        <router-view class="col" />
    </main>
</template>

<script>
    import PandoraSwitcher from "../components/PandoraSwitcher.vue";

    export default {
        components: {
            PandoraSwitcher,
        },
    };
</script>
