import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import PandoraView from "../views/PandoraView.vue";
import TribeView from "../views/TribeView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/boxes",
        name: "Pandora",
        component: PandoraView,
        redirect: "/boxes/",

        children: [
            {
                path: "",
                name: "MagicBoxes",
                component: () => import("../views/Pandora/MagicBoxes.vue"),
            },
            {
                path: "my_box",
                name: "MyBoxes",
                component: () => import("../views/Pandora/MyBoxes.vue"),
            },
            {
                path: "beneficiaries",
                name: "Beneficiaries",
                component: () => import("../views/Pandora/Beneficiaries.vue"),
            },
        ],
    },
    {
        path: "/tribe",
        name: "Tribe",
        component: TribeView,
        redirect: "/tribe/",
        children: [
            {
                path: "",
                name: "Overview",
                component: () => import("../views/Tribe/Overview.vue"),
            },
            {
                path: "tribe_details",
                name: "Details",
                component: () => import("../views/Tribe/Details.vue"),
            },
        ],
    },

    {
        path: "/:notFound(.*)",
        // component: () => import("@/views/NotFound.vue"),
        // redirect: "/",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
