<template>
    <div
        class="switcher"
        :class="{
            'overview': activeRoute === 'Overview',
            'details': activeRoute === 'Details',
        }"
    >
        <ul>
            <router-link @click="activeRoute = 'Overview'" :to="{ name: 'Overview' }" tag="li" class="overview">Tribe overview</router-link>

            <router-link @click="activeRoute = 'Details'" :to="{ name: 'Details' }" tag="li" class="details">My tribe's details</router-link>
        </ul>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                activeRoute: "Overview",
            };
        },
        methods: {
            alert(name) {
                window.alert(name);
            },
        },
    };
</script>
