<template>
    <div
        class="switcher"
        :class="{
            'all-boxes': activeRoute === 'MagicBoxes',
            'my-boxes': activeRoute === 'MyBoxes',
            'benef-boxes': activeRoute === 'Beneficiaries',
        }"
    >
        <ul>
            <router-link @click="activeRoute = 'MagicBoxes'" :to="{ name: 'MagicBoxes' }" tag="li" class="all-boxes">Magic Boxes</router-link>

            <router-link @click="activeRoute = 'MyBoxes'" :to="{ name: 'MyBoxes' }" tag="li" class="my-boxes">My Box</router-link>

            <router-link @click="activeRoute = 'Beneficiaries'" :to="{ name: 'Beneficiaries' }" tag="li" class="benef-boxes">Beneficiaries</router-link>
        </ul>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                activeRoute: "MagicBoxes",
            };
        },
        methods: {
            alert(name) {
                window.alert(name);
            },
        },
    };
</script>
