import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import config from "../../config.json";
var conf = config[config.env];
export default new Vuex.Store({
    state: {
        currentAddress: "0x0000000000000000000000000000000000000000",

        siteData: null,
        allInvestments: null,
        boxLevelToData: null,
        boxToEpochs: null,
        userData: null,
        tribeData: null,
        userChildrenLength: 0,
        userLevelToRounds: null,
        userRounds: null,
        userCoinBalance: null,

        notifications: [],
        currentChain: "",
        selectedWallet: "",
        currentBlockchain: null,

        preselectedChain: Number(conf.PRIMARY_BLOCKCHAIN.chainId),
    },
    mutations: {
        setSiteData(state, data) {
            state.siteData = data;
        },
        setAllInvestments(state, data) {
            state.allInvestments = data;
        },
        addToAllInvestments(state, investmentsData) {
            const { data, boxType, epoch } = investmentsData;
            // const resObj = { ...state.userRounds };
            // resObj[boxType][epoch] = data[boxType][epoch];
            // state.userRounds = resObj;
            const obj = { ...state.allInvestments[boxType] };
            obj[epoch] = data[boxType][epoch];

            Vue.set(state.allInvestments, boxType, obj);
        },
        setBoxToEpochs(state, data) {
            state.boxToEpochs = data;
        },
        setBoxLevelToData(state, data) {
            state.boxLevelToData = data;
        },
        setUserStats(state, data) {
            state.userData = data;
        },
        setTribeData(state, data) {
            state.tribeData = data;
        },
        setUserRounds(state, data) {
            state.userRounds = data;
        },
        addToUserRound(state, roundData) {
            const { data, boxType, epoch } = roundData;
            // const resObj = { ...state.userRounds };
            // resObj[boxType][epoch] = data[boxType][epoch];
            // state.userRounds = resObj;
            const obj = { ...state.userRounds[boxType] };
            obj[epoch] = data[boxType][epoch];
            Vue.set(state.userRounds, boxType, obj);
        },

        setUserChildrenLength(state, data) {
            state.userChildrenLength = data;
        },
        setUserLevelToRoundsLength(state, data) {
            state.levelToRoundsLength = data;
        },
        setCurrentAddress(state, data) {
            state.currentAddress = data;
        },
        setUserCoinBalance(state, data) {
            state.userCoinBalance = data;
        },

        setCurrentBlockchain(state, data) {
            state.currentBlockchain = data;
            state.currency = conf.NETWORK_PARAMS.find((el) => Number(el.chainId) === data)?.params?.nativeCurrency?.symbol || "BNB";
        },
        push_notification(state, notification) {
            const isMessageUnique = state.notifications.findIndex((el) => el.message.toLowerCase() === notification.message.toLowerCase());
            if (isMessageUnique < 0) {
                state.notifications.push({
                    ...notification,
                    id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
                });
            }
        },
        clear_notifications(state, data) {
            state.notifications = data;
        },
        remove_notification(state, notificationToRemove) {
            state.notifications = state.notifications.filter((notification) => notification.id != notificationToRemove.id);
        },
        setChainId(state, chainId) {
            state.currentChain = chainId;
        },
    },
    actions: {},
    modules: {},
});
