<template>
    <main class="main-pandora">
        <section class="section-switcher">
            <TribeSwitcher />
        </section>

        <router-view class="col" />
    </main>
</template>

<script>
    import TribeSwitcher from "../components/TribeSwitcher.vue";

    export default {
        components: {
            TribeSwitcher,
        },
    };
</script>
