<script setup>
    import { mapState } from "vuex";
    import IconArrowDown from "../IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="connect-wrap row">
        <button
            v-if="!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000'"
            class="btn btn-connect"
            @click="$emit('connectWallet')"
        >
            <span>Connect Wallet</span>
        </button>

        <template v-else>
            <div class="wallet-balance">
                <i class="icon-box">
                    <img src="../../assets/images/network/bnb.png" alt="" />
                </i>
                <span>{{ userCoinBalance ? parseFloat(Number(userCoinBalance).toFixed(4)) : "0.00" }}</span>
            </div>

            <div class="drop-down drop-down-wallet btn-drop-down-mobile">
                <button class="btn-drop-down">
                    <i class="icon-box">
                        <img src="../../assets/images/lang/4.png" alt="" />
                    </i>
                </button>
            </div>

            <div class="wallet-data-mobile-menu">
                <div class="row">
                    <i class="icon-box">
                        <img src="../../assets/images/lang/4.png" alt="" />
                    </i>
                    <span>{{ makeShort(currentAddress) }}</span>
                    <button @click="disconnect()" class="btn-mobile-logout">
                        <img src="../../assets/images/common/mobile-logout.svg" alt="" />
                    </button>
                </div>
            </div>

            <div class="drop-down drop-down-wallet btn-drop-down-desktop" :class="{ active: dropDown }">
                <!-- <div class="drop-down drop-down-wallet active"> -->
                <div class="drop-down-bg-click" @click="dropDown = false"></div>

                <button class="btn-drop-down" @click="dropDown = !dropDown">
                    <i class="icon-box">
                        <img src="../../assets/images/lang/4.png" alt="" />
                    </i>
                    <span>{{ makeShort(currentAddress) }}</span>
                    <div class="icon-arrow">
                        <IconArrowDown />
                    </div>
                </button>

                <div class="items-wrap">
                    <div class="items-container">
                        <ul>
                            <li @click="disconnect()">Logout</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        data() {
            return {
                dropDown: false,
            };
        },
        computed: {
            ...mapState(["currentAddress", "userCoinBalance"]),
        },
        methods: {
            makeShort(addr) {
                if (addr) {
                    return addr.slice(0, 5) + "..." + addr.slice(addr.length - 5, addr.length);
                }
            },
            async disconnect() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet === "metamask" || selectedWallet === "trust") {
                    localStorage.removeItem("address");
                    localStorage.removeItem("selectedWallet");
                    localStorage.removeItem("hideBalance");
                    this.$router.push("/");
                    location.reload();
                } else if (selectedWallet === "walletconnect") {
                    localStorage.removeItem("hideBalance");
                    await this.$root.core.killSession();
                } else if (selectedWallet === "email") {
                    localStorage.removeItem("email");
                    localStorage.removeItem("address");
                    localStorage.removeItem("selectedWallet");
                    localStorage.removeItem("hideBalance");
                    eraseCookie("login");
                    this.$router.push("/");
                    location.reload();
                }
            },
        },
    };
</script>
